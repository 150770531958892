import 'animate.css/animate.css'
import { Container, Section } from 'bloomer'
import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Account from './Account'
import Alerts from './Alerts'
import './App.css'
import Documentation from './Documentation'
import Home from './Home'
import NavBar from './NavBar'
import Pricing from './Pricing'
import Privacy from './Privacy'
import PrivateRoute from './PrivateRoute'
import Spinner from './Spinner'
import { StoreProvider } from './Store'

class App extends Component {
  render() {
    return (
      <Router>
        <StoreProvider>
          <Spinner />
          <Alerts />
          <NavBar />
          <Section style={{ paddingTop: '2rem' }}>
            <Container>
              <Route path="/" exact component={Home} />
              <Route path="/documentation" component={Documentation} />
              <Route path="/pricing" component={Pricing} />
              <Route path="/privacy" component={Privacy} />
              <PrivateRoute path="/account" component={Account} />
            </Container>
          </Section>
        </StoreProvider>
      </Router>
    )
  }
}

export default App
