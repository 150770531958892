import React, { Component } from 'react'
import {
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardHeader,
  ModalCardTitle,
  ModalCardBody,
  ModalCardFooter,
  Delete,
  Button
} from 'bloomer'
import { injectStripe } from 'react-stripe-elements'
import { CardElement } from 'react-stripe-elements'
import { withStore } from './Store'
import PropTypes from 'prop-types'

class StripeCard extends Component {
  static propTypes = { onChange: PropTypes.func.isRequired }

  options = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#9e2146'
      }
    }
  }
  render() {
    return (
      <CardElement
        {...this.options}
        onChange={this.props.onChange}
        onReady={el => el.focus()}
      />
    )
  }
}

class AccountCard extends Component {
  state = { isLoading: false, complete: false, error: '' }

  static propTypes = {
    store: PropTypes.object.isRequired,
    stripe: PropTypes.object.isRequired,
    isActive: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    button: PropTypes.string.isRequired
  }

  static defaultProps = {
    isActive: false,
    button: 'Submit'
  }

  onChange = ({ complete, error }) => {
    const { store } = this.props
    if (error) store.addAlert('warning', error)
    this.setState({ complete })
  }

  handleClick = e => {
    const { stripe, store, onClose } = this.props
    e.preventDefault()
    this.setState({ isLoading: true })
    stripe
      .createToken()
      .then(({ token }) => {
        this.setState({ isLoading: false })
        onClose(token)
      })
      .catch(error => {
        store.addAlert('danger', error)
        this.setState({ isLoading: false })
      })
  }

  render() {
    const { isActive, title, onClose, button } = this.props
    const { isLoading, complete } = this.state
    return (
      <Modal isActive={isActive}>
        <ModalBackground />
        <ModalCard>
          <ModalCardHeader>
            <ModalCardTitle>{title}</ModalCardTitle>
            <Delete onClick={() => onClose()} />
          </ModalCardHeader>
          <ModalCardBody style={{ paddingRight: 0 }}>
            <StripeCard onChange={this.onChange} />
          </ModalCardBody>
          <ModalCardFooter>
            <Button aria-label="cancel" onClick={() => onClose()}>
              Cancel
            </Button>
            <Button
              aria-label="login"
              isColor="primary"
              isLoading={isLoading}
              onClick={this.handleClick}
              disabled={!complete}
            >
              {button}
            </Button>
          </ModalCardFooter>
        </ModalCard>
      </Modal>
    )
  }
}

export default withStore(injectStripe(AccountCard))
