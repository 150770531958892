import http from './http'
import { auth } from './firebase'

function updateProfile(displayName) {
  return auth.currentUser.updateProfile({ displayName })
}

function updateEmail(email) {
  return auth.currentUser.updateEmail(email)
}

function requestLogin(email) {
  return auth.sendSignInLinkToEmail(email, {
    url: window.location.href,
    handleCodeInApp: true
  })
}

function login(email) {
  return auth
    .signInWithEmailLink(email, window.location.href)
    .then(result => result.user.uid)
}

function search(params) {
  return http.get(`/providers`, params)
}

function changeToken() {
  return http.put('/token')
}

function subscribe(token) {
  return http.put('/subscription', token ? { token } : null)
}

function unsubscribe() {
  return http.delete('/subscription')
}

export default {
  search,
  updateProfile,
  updateEmail,
  requestLogin,
  login,
  changeToken,
  subscribe,
  unsubscribe
}
