import React from 'react'
import { Columns, Column } from 'bloomer'
import AccountInfo from './AccountInfo'
import AccountSubscription from './AccountSubscription'
import { StripeProvider } from 'react-stripe-elements'

const Account = () => {
  document.title = 'NPIAPI - Account'
  return (
    <Columns isDesktop={true}>
      <Column>
        <AccountInfo />
      </Column>
      <Column>
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLIC}>
          <AccountSubscription />
        </StripeProvider>
      </Column>
    </Columns>
  )
}

export default Account
