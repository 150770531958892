import React, { Component, Fragment } from 'react'
import { Box, Title } from 'bloomer'
import AccountCard from './AccountCard'
import Wrap from './Wrap'
import Confirm from './Confirm'
import * as isBefore from 'date-fns/is_before'
import capitalize from 'lodash/capitalize'
import { Elements } from 'react-stripe-elements'
import { withStore } from './Store'
import api from './api'
import PropTypes from 'prop-types'

class AccountSubscription extends Component {
  static propTypes = { store: PropTypes.object.isRequired }

  state = {
    showCard: false,
    cardTitle: '',
    cardButton: '',
    confirmCancel: false
  }

  toggleSubscription = status => {
    const { store } = this.props
    if (status === 'Premium') {
      return store.addAlert(
        'warning',
        'Please contact support to change your Premium Account'
      )
    } else if (status === 'Active') {
      return this.unsubscribe()
    } else if (status === 'Canceling') {
      return this.reactivate()
    } else {
      // Trialing, Trial Ended, Canceled, Past Due, Unpaid
      return this.subscribe()
    }
  }

  unsubscribe = value => {
    const { store } = this.props
    if (!value) return this.setState({ confirmCancel: true })
    store.showSpinner()
    api
      .unsubscribe()
      .then(() => {
        store.addAlert('warning', 'Your subscription is canceled')
        this.setState({ confirmCancel: false })
      })
      .catch(error => store.addAlert('danger', error))
      .finally(() => store.hideSpinner())
  }

  reactivate = value => {
    const { store } = this.props
    if (!value) return this.setState({ confirmReactivate: true })
    store.showSpinner()
    api
      .subscribe()
      .then(() => {
        store.addAlert('success', 'Your subscription reactivated')
        this.setState({ confirmReactivate: false })
      })
      .catch(error => store.addAlert('danger', error))
      .finally(() => store.hideSpinner())
  }

  subscribe = () => {
    this.setState({
      showCard: true,
      cardTitle: 'Subscribe ($50/month)',
      cardButton: 'Subscribe'
    })
  }

  changeCard = () => {
    this.setState({
      showCard: true,
      cardTitle: 'Update Your Card',
      cardButton: 'Update'
    })
  }

  handleCard = token => {
    this.setState({ showCard: false })
    if (!token) return
    const { store } = this.props
    store.showSpinner()
    api
      .subscribe(token.id)
      .then(() => {
        store.addAlert('success', 'Your Account is Updated')
        this.setState({ showCard: false })
      })
      .catch(error => store.addAlert('danger', error))
      .finally(() => store.hideSpinner())
  }

  render() {
    const {
      confirmCancel,
      confirmReactivate,
      showCard,
      cardTitle,
      cardButton
    } = this.state

    const { subscription = {}, trialEnd, isPremium } = this.props.store.user

    function cardString() {
      const { card } = subscription
      return card && `${card.brand}: ${card.last4} (${card.month}/${card.year})`
    }

    function isTrialing() {
      return trialEnd && isBefore(new Date(), trialEnd.toDate())
    }

    function periodEndString() {
      const timestamp = subscription.periodEnd
      return timestamp && timestamp.toDate().toDateString()
    }

    function trialEndString() {
      return trialEnd && trialEnd.toDate().toDateString()
    }

    function status() {
      if (isPremium) return 'Premium'
      if (!subscription.subscriptionId) {
        return isTrialing()
          ? 'Trialing'
          : subscription.customerId
          ? 'Canceled'
          : 'Trial Ended'
      }
      return subscription.canceled
        ? 'Canceling'
        : capitalize(subscription.status)
    }

    return (
      <Fragment>
        <Box>
          <Title tag="h2">Subscription</Title>
          <Wrap>
            <table className="table is-fullwidth app-list hover">
              <tbody>
                <tr onClick={() => this.toggleSubscription(status())}>
                  <th>Status</th>
                  <td>{status()}</td>
                </tr>
                {status() === 'Canceling' && (
                  <tr onClick={() => this.toggleSubscription(status())}>
                    <th>Cancel On</th>
                    <td>{periodEndString()}</td>
                  </tr>
                )}
                {status() === 'Active' && (
                  <tr onClick={() => this.toggleSubscription(status())}>
                    <th>Next Bill</th>
                    <td>{periodEndString()}</td>
                  </tr>
                )}
                {(status() === 'Trialing' || status() === 'Trial End') && (
                  <tr onClick={() => this.toggleSubscription(status())}>
                    <th>Trial End</th>
                    <td>{trialEndString()}</td>
                  </tr>
                )}
                {status() === 'Active' && (
                  <tr onClick={this.changeCard}>
                    <th>Card</th>
                    <td>{cardString()}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </Wrap>
        </Box>
        {showCard && (
          <Elements>
            <AccountCard
              isActive={showCard}
              title={cardTitle}
              button={cardButton}
              onClose={this.handleCard}
            />
          </Elements>
        )}
        <Confirm
          isActive={confirmCancel}
          title="Cancel Subscription"
          type="warning"
          onCancel={() => this.setState({ confirmCancel: false })}
          onConfirm={() => this.unsubscribe(true)}
        >
          <p>
            Are you sure you want to <strong>cancel</strong> your subscription?
            At the end of the billing period you will no longer have access to
            the API.
          </p>
        </Confirm>
        <Confirm
          isActive={confirmReactivate}
          title="Reactivate Subscription"
          type="success"
          onCancel={() => this.setState({ confirmReactivate: false })}
          onConfirm={() => this.reactivate(true)}
        >
          <p>
            Are you sure you want to <strong>reactivate</strong> your
            subscription? You will have access to the API immediately and
            billing will continue as before.
          </p>
        </Confirm>
      </Fragment>
    )
  }
}

export default withStore(AccountSubscription)
