import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import ModalTransition from './ModalTransition'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import {
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardHeader,
  ModalCardTitle,
  ModalCardBody,
  ModalCardFooter,
  Field,
  Input,
  Button,
  Delete,
  Control,
  Icon
} from 'bloomer'

import { auth } from './firebase'
import { withStore } from './Store'
import PropTypes from 'prop-types'

class Login extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    isActive: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  }

  static defaultProps = {
    title: 'Sign In'
  }

  state = {
    email: '',
    isLoading: false,
    emailSent: false
  }

  handleInputChange = event => {
    this.setState({ email: event.target.value })
  }

  handleKeyPress = event => {
    if (event.key === 'Enter') this.login()
  }

  login = () => {
    const { store } = this.props
    this.setState({ isLoading: true })
    return auth
      .sendSignInLinkToEmail(this.state.email, {
        url: window.location.href,
        handleCodeInApp: true
      })
      .then(() => {
        window.localStorage.setItem('emailForSignIn', this.state.email)
        this.setState({ emailSent: true })
      })
      .catch(error => store.addAlert('danger', error))
      .finally(() => this.setState({ isLoading: false }))
  }

  render() {
    return (
      <ModalTransition in={this.props.isActive}>
        <Modal isActive={this.props.isActive}>
          <ModalBackground />
          <ModalCard>
            <ModalCardHeader>
              <ModalCardTitle>{this.props.title}</ModalCardTitle>
              <Delete onClick={this.props.onClose} />
            </ModalCardHeader>
            <ModalCardBody>
              {this.state.emailSent ? (
                <p>
                  An Email was sent to {this.state.email}. Please click on the
                  link in the email to login.
                </p>
              ) : (
                <Field>
                  <Control hasIcons="left">
                    <Input
                      isSize="large"
                      type="email"
                      aria-label="email"
                      autoCapitalize="none"
                      value={this.state.email}
                      onKeyUp={this.handleKeyPress}
                      onChange={this.handleInputChange}
                      placeholder="youremail@yourdomain.com"
                      required
                    />
                    <Icon isAlign="left">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </Icon>
                  </Control>
                </Field>
              )}
            </ModalCardBody>
            <ModalCardFooter>
              <Button aria-label="cancel" onClick={this.props.onClose}>
                Cancel
              </Button>
              {!this.state.emailSent && (
                <Button
                  aria-label="login"
                  isColor="primary"
                  isLoading={this.state.isLoading}
                  onClick={this.login}
                  disabled={!this.state.email}
                >
                  Login
                </Button>
              )}
            </ModalCardFooter>
          </ModalCard>
        </Modal>
      </ModalTransition>
    )
  }
}

export default withStore(withRouter(Login))
