import React from 'react'
import { Modal, ModalBackground, ModalContent } from 'bloomer'
import { withStore } from './Store'
import PropTypes from 'prop-types'

const Spinner = ({ store }) => (
  <Modal className="app-modal-spinner" isActive={store.spinnerIsActive}>
    <ModalBackground />
    <ModalContent>
      <div className="spinner" />
    </ModalContent>
  </Modal>
)

Spinner.propTypes = { store: PropTypes.object.isRequired }

export default withStore(Spinner)
