import React from 'react'
import CSSTransition from 'react-transition-group/CSSTransition'
import PropTypes from 'prop-types'

const ModalTransition = props => (
  <CSSTransition {...props} classNames="modal" timeout={300} />
)

ModalTransition.propTypes = { props: PropTypes.array }

export default ModalTransition
