import React, { Component, Fragment } from 'react'
import Wrap from './Wrap'
import Code from './Code'
import { Field, Input, Button, Icon, Control } from 'bloomer'
import api from './api'
import Clipboard from 'react-clipboard.js'
import { withStore } from './Store'
import qs from 'qs'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const API_URL = process.env.REACT_APP_API_URL

class Search extends Component {
  static propTypes = { store: PropTypes.object.isRequired }

  state = {
    results: null,
    isLoading: false,
    query: '',
    endpoint: ''
  }

  examples = [
    {
      description: 'Last name begins with Smit limit 10',
      example: 'names.last_name=smit*&limit=10'
    },
    {
      description: 'Last name is Smith limit 10',
      example: 'names.last_name=smith&limit=10'
    },
    {
      description: 'Last name is Smith in KY limit 10',
      example: 'names.last_name=smith&addresses.state=KY&limit=10'
    },
    {
      description: 'Taxonomy is 207VG0400X in KY limit 10',
      example: 'taxonomies.code=207VG0400X&taxonomies.state=KY&limit=10'
    },
    {
      description: 'Organization name starts with America limit 10',
      example: 'names.organization_name=america*&limit=10'
    },
    {
      description: 'Organization name starts with America next 10',
      example: 'names.organization_name=america*&limit=10&offset=10'
    }
  ]

  searchQuery = () => {
    const { store } = this.props
    const token = store.user.token
    const params = qs.parse(this.state.query)
    const query = { ...params, token }
    const endpoint = `${API_URL}/providers?${qs.stringify(query)}`
    this.setState({ isLoading: true })
    api
      .search(params)
      .then(results => this.setState({ endpoint, results }))
      .catch(error => store.addAlert('warning', error))
      .finally(() => this.setState({ isLoading: false }))
  }

  searchExample = item => {
    this.setState({ query: item.example })
  }

  onCopyQuery = () => {
    const { store } = this.props
    store.addAlert('primary', 'Query Copied to Clipboard')
  }

  handleInputChange = e => {
    this.setState({ query: e.target.value, results: null })
  }

  handleKeyPress = e => {
    if (e.key === 'Enter') this.searchQuery()
  }

  render() {
    return (
      <Fragment>
        <Field hasAddons={true}>
          <Control isExpanded={true} hasIcons="left">
            <Input
              disabled={this.state.isLoading}
              isSize="medium"
              type="search"
              aria-label="search"
              autoCapitalize="none"
              value={this.state.query}
              onKeyUp={this.handleKeyPress}
              onChange={this.handleInputChange}
            />
            <Icon isAlign="left">
              <FontAwesomeIcon icon={faSearch} />
            </Icon>
          </Control>
          <Control>
            <Button
              isColor="primary"
              isSize="medium"
              isLoading={this.state.isLoading}
              disabled={this.state.isLoading || !this.state.query}
              aria-label="search"
              onClick={this.searchQuery}
            >
              <Icon>
                <FontAwesomeIcon icon={faSearch} />
              </Icon>
            </Button>
          </Control>
        </Field>
        {this.state.query && this.state.results ? (
          <Fragment>
            <h3 className="is-size-4">Query</h3>
            <span className="hover">
              <Clipboard
                component="span"
                onSuccess={this.onCopyQuery}
                data-clipboard-text={this.state.endpoint}
              >
                <Code>{this.state.endpoint}</Code>
              </Clipboard>
            </span>
            <h3 className="is-size-4">Results</h3>
            <Code>{JSON.stringify(this.state.results, null, 2)}</Code>
          </Fragment>
        ) : (
          <Fragment>
            <h3 className="is-size-4">Examples</h3>
            <Wrap>
              <table className="table is-fullwidth app-list">
                <tbody>
                  {this.examples.map(item => (
                    <tr
                      className="hover"
                      key={item.description}
                      onClick={() => this.searchExample(item)}
                    >
                      <td>
                        <strong>{item.description}</strong>
                        <br />
                        {item.example}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Wrap>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export default withStore(Search)
