import React, { Component, Fragment } from 'react'
import { Link, NavLink, withRouter } from 'react-router-dom'
import logo from './NavLogo.png'
import Login from './Login'
import {
  Navbar,
  Container,
  NavbarBrand,
  NavbarBurger,
  NavbarMenu,
  NavbarStart,
  NavbarEnd
} from 'bloomer'

import { auth } from './firebase'
import { withStore } from './Store'
import PropTypes from 'prop-types'

class NavBar extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  state = { isActive: false, showLogin: false }

  componentDidMount() {
    const { history, store } = this.props
    if (auth.isSignInWithEmailLink(window.location.href)) {
      const email = window.localStorage.getItem('emailForSignIn')
      if (!email)
        return store.addAlert(
          'danger',
          'Link expired or originated on a different device'
        )
      store.showSpinner()
      auth
        .signInWithEmailLink(email, window.location.href)
        .then(() => {
          window.localStorage.removeItem('emailForSignIn')
          history.push('/')
        })
        .catch(error => store.addAlert('danger', error))
        .finally(() => store.hideSpinner())
    }
  }

  onClickNav = () => {
    this.setState(state => ({ isActive: !state.isActive }))
  }
  login = e => {
    e.preventDefault()
    this.setState(state => ({ isActive: !state.isActive, showLogin: true }))
  }
  logout = e => {
    e.preventDefault()
    this.setState(state => ({ isActive: !state.isActive }))
    auth.signOut()
    this.props.history.push('/')
  }
  render() {
    const { user } = this.props.store
    return (
      <Fragment>
        <Navbar className="has-shadow is-dark" aria-label="main navigation">
          <Container>
            <NavbarBrand>
              <Link to="/" className="navbar-item">
                <img src={logo} alt="NPIAPI" />
              </Link>
              <NavbarBurger
                isActive={this.state.isActive}
                onClick={this.onClickNav}
              />
            </NavbarBrand>
            <NavbarMenu isActive={this.state.isActive}>
              <NavbarStart>
                <NavLink
                  to="/"
                  exact
                  className="navbar-item"
                  activeClassName="is-active"
                  onClick={this.onClickNav}
                >
                  {user ? 'Testing' : 'Home'}
                </NavLink>
                <NavLink
                  to="/documentation"
                  className="navbar-item"
                  activeClassName="is-active"
                  onClick={this.onClickNav}
                >
                  Documentation
                </NavLink>
                {user ? null : (
                  <NavLink
                    to="/pricing"
                    className="navbar-item"
                    activeClassName="is-active"
                    onClick={this.onClickNav}
                  >
                    Pricing
                  </NavLink>
                )}
                {user ? (
                  <NavLink
                    to="/account"
                    className="navbar-item"
                    activeClassName="is-active"
                    onClick={this.onClickNav}
                  >
                    Account
                  </NavLink>
                ) : null}
              </NavbarStart>
              <NavbarEnd>
                <a
                  className="navbar-item"
                  href="http://status.npiapi.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={this.onClickNav}
                >
                  Status
                </a>
                {user ? (
                  <a href="/" className="navbar-item" onClick={this.logout}>
                    Logout
                  </a>
                ) : (
                  <a href="/" className="navbar-item" onClick={this.login}>
                    Login
                  </a>
                )}
              </NavbarEnd>
            </NavbarMenu>
          </Container>
        </Navbar>
        <Login
          title="Sign In"
          isActive={this.state.showLogin}
          onClose={() => this.setState({ showLogin: false })}
        />
      </Fragment>
    )
  }
}

export default withStore(withRouter(NavBar))
