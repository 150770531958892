import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Control,
  Delete,
  Field,
  Icon,
  Input,
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHeader,
  ModalCardTitle
} from 'bloomer'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ModalTransition from './ModalTransition'

class Prompt extends Component {
  static propTypes = {
    value: PropTypes.any.isRequired,
    isActive: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  }

  static defaultProps = {
    value: '',
    isActive: false,
    title: 'Prompt',
    type: 'primary'
  }

  state = { value: this.props.value }

  handleInputChange = e => {
    this.setState({ value: e.target.value })
  }

  handleKeyPress = e => {
    if (e.key === 'Enter') this.handleSubmit()
  }

  handleSubmit = () => {
    this.props.onSubmit(this.state.value)
  }

  render() {
    const { isActive, title, type, onCancel } = this.props
    return (
      <ModalTransition in={isActive}>
        <Modal isActive={isActive}>
          <ModalBackground />
          <ModalCard>
            <ModalCardHeader>
              <ModalCardTitle>{title}</ModalCardTitle>
              <Delete onClick={onCancel} />
            </ModalCardHeader>
            <ModalCardBody>
              <Field>
                <Control isExpanded={true} hasIcons="left">
                  <Input
                    isSize="medium"
                    type="text"
                    autoCapitalize="none"
                    value={this.state.value || ''}
                    onKeyUp={this.handleKeyPress}
                    onChange={this.handleInputChange}
                  />
                  <Icon isAlign="left">
                    <FontAwesomeIcon icon={faInfo} />
                  </Icon>
                </Control>
              </Field>
            </ModalCardBody>
            <ModalCardFooter>
              <Button aria-label="cancel" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                aria-label="login"
                isColor={type}
                onClick={this.handleSubmit}
              >
                Submit
              </Button>
            </ModalCardFooter>
          </ModalCard>
        </Modal>
      </ModalTransition>
    )
  }
}

export default Prompt
