import React from 'react'
import { Box, Title } from 'bloomer'
import Section from './Section'

const Pricing = () => {
  document.title = 'NPIAPI - Pricing'
  return (
    <Box>
      <Title tag="h1">Pricing</Title>

      <Section title="Monthly Fee">
        <p>NPIAPI pricing is $50/month charged to a credit card. </p>
      </Section>

      <Section title="Trial Period">
        <p>
          When you sign up for NPIAPI, you will automatically begin your 14 day
          trial period. At the end of the 14 days your access to the API will
          end unless you sign up for a subscription.
        </p>
      </Section>

      <Section title="Cancellation">
        <p>
          You may cancel at any time. When you cancel your subscription, your
          access will remain open until the end of the current billing cycle. At
          the end of the billing cycle your access to the API will end.
        </p>
      </Section>
    </Box>
  )
}

export default Pricing
