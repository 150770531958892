import React from 'react'
import PropTypes from 'prop-types'

const Wrap = ({ children }) => (
  <div style={{ width: '100%', overflowX: 'auto' }}>{children}</div>
)

Wrap.propTypes = { children: PropTypes.any.isRequired }

export default Wrap
