import React from 'react'
import { Box, Title } from 'bloomer'
import Section from './Section'

const Privacy = () => {
  document.title = 'NPIAPI - Privacy'
  return (
    <Box>
      <Title tag="h1">Privacy</Title>

      <Section title="Information Collected">
        <p>
          Your email address is used to login. Your credit card information is
          used to charge your subscription.
        </p>
      </Section>

      <Section title="Data Protection">
        <p>
          Credit card information is stored on our billing system (stripe.com).
        </p>
      </Section>

      <Section title="Passwords">
        <p>We don&#39;t use passwords.</p>
      </Section>

      <Section title="Cookies">
        <p>We don&#39;t use cookies.</p>
      </Section>

      <Section title="3rd Party Links">
        <p>We don&#39;t link to 3rd parties.</p>
      </Section>

      <Section title="Sharing">
        <p>We don&#39;t share your information.</p>
      </Section>

      <Section title="COPPA">
        <p>Our product is meant for people over 13.</p>
      </Section>

      <Section title="Consent">
        <p>If you use our service, then you consent to this privacy policy.</p>
      </Section>

      <Section title="Changes">
        <p>
          If this privacy policy changes, then it will be reflected on this
          page.
        </p>
      </Section>
    </Box>
  )
}

export default Privacy
