import React from 'react'
import { Content } from 'bloomer'
import PropTypes from 'prop-types'

const Section = ({ title, subtitle, children }) => (
  <div className="app-section">
    <h2 className="is-size-4">{title}</h2>
    {subtitle ? <h3 className="is-size-5">{subtitle}</h3> : null}
    <Content>{children}</Content>
  </div>
)

Section.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.any.isRequired
}

export default Section
