import React, { Component, Fragment } from 'react'
import Section from './Section'
import { Title, Notification, Button } from 'bloomer'
import Code from './Code'
import Login from './Login'

class Landing extends Component {
  state = { showLogin: false }
  login = () => this.setState({ showLogin: true })
  render() {
    return (
      <Fragment>
        <Title tag="h1">Integrate your App with the NPI Registry</Title>
        <Notification style={{ marginLeft: '1rem' }}>
          <p>
            NPIAPI provides a real-time search capability into the NPPES NPI
            Registry with an API that makes it easy to integrate the NPI data
            into your own applications.
          </p>
        </Notification>

        <Section title="Easy to use API">
          <Code>{`# Get provider with NPI of 12345678
curl https://npiapi.com/api/providers/12345678

# Search for a provider with filters
curl https://npiapi.com/api/providers?names.last_name=myname&addresses.state=OH`}</Code>
        </Section>

        <Section title="Search by ANY Field">
          <Code>
            {`# Last name begins with Smit
curl https://npiapi.com/api/providers?names.last_name=smit*

# Last name is Smith
curl https://npiapi.com/api/providers?names.last_name=smith

# Last name is Smith in KY
curl https://npiapi.com/api/providers?names.last_name=smith&addresses.state=KY

# Taxonomy code is 207VG0400X from KY
curl https://npiapi.com/api/providers?taxonomies.code=207VG0400X&taxonomies.state=KY

# Organization name starts with Awesome in KY limit 10
curl https://npiapi.com/api/providers?names.organization_name=awesome*&addresses.state=KY&limit=10

# Organization name starts with Awesome in KY next 10
curl https://npiapi.com/api/providers?names.organization_name=awesome*&addresses.state=KY&limit=10&offset=10`}
          </Code>
        </Section>

        <Section title="JSON Documents">
          <Code>
            {`# Get a specific provider
curl https://npiapi.com/api/providers/12345678
{
  "npi": "12345678",
  "entity_type": "1"
}

# Search for a provider
curl https://npiapi.com/api/providers?names.organization_name=awesome
{
  "total": 2,
  "count": 2,
  "offset": 0,
  "providers": [
    { "npi": "12345678", "entity_type": "1", ...},
    { "npi": "23456789", "entity_type": "1", ...}
  ]
}`}
          </Code>
        </Section>

        <Section title="Free Trial">
          <Notification>
            <p>
              If you are ready to give NPIAPI a try, sign up for a 14 day free
              trial. No credit card required, just your email address to get
              started.
            </p>
            <Button
              isColor="primary"
              aria-label="sign up for a free trial"
              onClick={this.login}
            >
              Sign Up for a Free Trial
            </Button>
          </Notification>
        </Section>
        <Login
          title="Sign Up"
          isActive={this.state.showLogin}
          onClose={() => this.setState({ showLogin: false })}
        />
      </Fragment>
    )
  }
}

export default Landing
