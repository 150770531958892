import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { v4 as uuid } from 'uuid'
import { auth, db } from './firebase'

const StoreContext = React.createContext()

export class StoreProvider extends Component {
  static propTypes = { children: PropTypes.any.isRequired }

  state = {
    user: null,
    account: null,
    spinnerIsActive: false,
    alerts: []
  }

  componentDidMount() {
    if (process.env.NODE_ENV === 'test') return
    this.unsubscribeAuth = auth.onAuthStateChanged(account => {
      if (this.unsubscribeUser) this.unsubscribeUser()
      this.updateAccount()
      if (!account) return this.updateUser(null)
      if (!this.state.user) this.updateUser({ email: auth.currentUser?.email })
      this.unsubscribeUser = db
        .collection('users')
        .doc(account.uid)
        .onSnapshot(doc => doc.data() && this.updateUser(doc.data()))
    })
  }

  componentWillUnmount() {
    if (this.unsubscribeAuth) this.unsubscribeAuth()
    if (this.unsubscribeUser) this.unsubscribeUser()
  }

  updateUser = user => this.setState({ user: user })

  updateAccount = () => this.setState({ account: auth.currentUser })

  showSpinner = () => this.setState({ spinnerIsActive: true })

  hideSpinner = () => this.setState({ spinnerIsActive: false })

  addAlert = (type, message = 'Unknown Error') => {
    const n = { id: uuid(), type, message: message.message || message }
    this.setState(state => ({ alerts: [...state.alerts, n] }))
    setTimeout(() => this.removeAlert(n), 3000)
  }

  removeAlert = alert => {
    this.setState(state => ({
      alerts: state.alerts.filter(n => n.id !== alert.id)
    }))
  }

  render() {
    return (
      <StoreContext.Provider
        value={{
          ...this.state,
          updateUser: this.updateUser,
          updateAccount: this.updateAccount,
          showSpinner: this.showSpinner,
          hideSpinner: this.hideSpinner,
          addAlert: this.addAlert,
          removeAlert: this.removeAlert
        }}
      >
        {this.props.children}
      </StoreContext.Provider>
    )
  }
}

export function withStore(Component) {
  return function WrappedComponent(props) {
    return (
      <StoreContext.Consumer>
        {store => <Component store={store} {...props} />}
      </StoreContext.Consumer>
    )
  }
}
