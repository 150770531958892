import React, { Component } from 'react'
import { Delete, Notification } from 'bloomer'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import CSSTransition from 'react-transition-group/CSSTransition'
import { withStore } from './Store'
import PropTypes from 'prop-types'

class Alerts extends Component {
  static propTypes = { store: PropTypes.object.isRequired }

  render() {
    const { store } = this.props
    return (
      <div className="app-notifications">
        <TransitionGroup>
          {store.alerts.map(alert => (
            <CSSTransition
              key={alert.id}
              classNames="notification"
              timeout={300}
            >
              <Notification
                className="hover"
                key={alert.id}
                isColor={alert.type}
                onClick={() => store.removeAlert(alert)}
              >
                <Delete />
                {alert.message}
              </Notification>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    )
  }
}

export default withStore(Alerts)
