import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { withStore } from './Store'
import PropTypes from 'prop-types'

const PrivateRoute = ({ component: Component, path, store }) => (
  <Route
    path={path}
    render={props =>
      store.user ? <Component {...props} /> : <Redirect to="/" />
    }
  />
)

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  store: PropTypes.object.isRequired
}

export default withStore(PrivateRoute)
