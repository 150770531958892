import axios from 'axios'
import { auth } from './firebase'

const client = axios.create({ baseURL: process.env.REACT_APP_API_URL })

function getToken() {
  return auth.currentUser
    ? auth.currentUser.getIdToken()
    : Promise.resolve(null)
}

function send(config) {
  return getToken()
    .then(token => {
      if (token) {
        config.headers = config.headers || {}
        config.headers.Authorization = `Bearer ${token}`
      }
      return client.request(config)
    })
    .then(response => response.data)
    .catch(error => {
      let err
      if (error.response) {
        err = new Error(error.response.data.message || error.response.data)
        err.status = error.response.status
      } else if (error.request) {
        err = new Error(error.request.message)
        err.status = 503
      } else {
        err = error
        err.status = 500
      }
      return Promise.reject(err)
    })
}

function get(url, params) {
  return send({ method: 'GET', url, params })
}

function post(url, data, params) {
  return send({ method: 'POST', url, data, params })
}

function put(url, data, params) {
  return send({ method: 'PUT', url, data, params })
}

function destroy(url, data, params) {
  return send({ method: 'DELETE', url, data, params })
}

export default { get, post, put, delete: destroy }
