import React from 'react'
import Section from './Section'
import Code from './Code'
import { Title, Box } from 'bloomer'
import { withStore } from './Store'
import PropTypes from 'prop-types'

const Documentation = ({ store }) => {
  const token = (store.user && store.user.token) || '123456789'
  document.title = `NPIAPI - Documentation`
  return (
    <Box>
      <Title tag="h1">Documentation</Title>

      <Section title="Authentication">
        <p>
          When you create an account, you will be assigned an
          <strong> Authentication Token</strong>, which you can find here in the
          Documentation when you are logged in or on the Account page. You must
          use that token as a query parameter for every search submitted to the
          API.
        </p>
        <Code>{`?token=${token}`}</Code>
      </Section>

      <Section
        title="Find a specific provider by NPI Number"
        subtitle="GET /api/providers/:npi"
      >
        <p>
          If you already know the NPI number of a provider, send a get request
          to <strong>/api/providers/:npi</strong> (replacing :npi with the NPI
          number of that provider). If not found, you will receive an empty
          reply with a 404 status code.
        </p>
        <Code>
          {`curl https://npiapi.com/api/providers/12345678?token=${token}
{
  "npi": "12345678",
  "entity_type": "1"
}`}
        </Code>
      </Section>

      <Section
        title="Search for a provider based on criteria"
        subtitle="GET /api/providers"
      >
        <p>
          To search for a provider, send a get request to
          <strong> /api/providers</strong> with the query parameters you need to
          limit the search. Any of the query parameters can be a
          <strong> begins with</strong> match by adding a <strong> * </strong>
          to the end of the value. Values are
          <strong> NOT </strong> case-sensitive.
        </p>
        <p>
          <strong> Any</strong> attribute inside a provider object is available
          for search. If the data is nested, specify that with a dot (.). For
          example, to search within the names array use names.last_name or
          names.first_name. You can
          <strong> combine</strong> as many of the query parameters together as
          you would like. Please see the
          <a href="#sample-provider-object"> Sample Provider Object </a>
          definition below for a list of all possible search parameters.
        </p>
        <p>
          Search results are contained inside an object that provides the
          <strong> total</strong> number of matches, the
          <strong> count</strong> of matches included in the results, the
          <strong> offset</strong> that was used in the query and an array of
          <strong> providers</strong> that matched the search. If no matches are
          found, the result object will indicate such and the providers array
          will be empty.
        </p>
        <Code>{`curl https://npiapi.com/api/providers?names.organization_name=mycompany*&taxonomies.code=282N00000X&token=${token}
{
  "total": 2,
  "count": 2,
  "offset": 0,
  "providers": [
    {"npi":"12345678", "entity_type":"1"},
    {"npi":"23456789", "entity_type":"1"}
  ]
}

curl https://npiapi.com/api/providers?names.organization_name=SOMETHINGINVALID&token=${token}
{
  "total": 0,
  "count": 0,
  "offset": 0,
  "providers": []
}`}</Code>
      </Section>

      <Section title="Search Limits and Pagination">
        <p>
          By default a limit of 100 results are returned at a time. You can
          limit that number further by sending the
          <strong> limit</strong> option. You can search for another batch of
          results (pagination) by sending the
          <strong> offset</strong> parameter.
        </p>
        <Code>{`curl https://npiapi.com/api/providers?names.last_name=myname&limit=2&token=${token}
{
  "total": 20,
  "count": 2,
  "offset": 0,
  "providers": [
    {"npi":"12345678", "entity_type":"1"},
    {"npi":"23456789", "entity_type":"1"}
  ]
}

curl https://npiapi.com/api/providers?names.last_name=myname&limit=2&offset=2&token={{ token }}
{
  "total": 20,
  "count": 2,
  "offset": 2,
  "providers": [
    {"npi":"34567890", "entity_type":"1"},
    {"npi":"45678901", "entity_type":"1"}
  ]
}`}</Code>
      </Section>

      <Section id="sample-provider-object" title="Sample Provider Object">
        <Code>{`{
  "npi": "1111111111",
  "entity_type": "1",
  "replace_npi": "1111111112",
  "employer_identification_number": "&lt;UNAVAIL&gt;
  "enumeration_date": "MM/DD/YYYY",
  "deactivation_code": "1",
  "deactivation_date": "MM/DD/YYYY",
  "reactivation_date": "MM/DD/YYYY",
  "last_update_date": "MM/DD/YYYY",
  "sole_proprietor": "Y",
  "subpart": "N",
  "parent_lbn": "COMMONWEALTH OF SOMEWHERE",
  "parent_tin": "&lt;UNAVAIL&gt;
  "official": {
    "name_prefix": "DR",
    "first_name": "JOHN",
    "middle_name": "ADAM",
    "last_name": "SMITH",
    "name_suffix": "III",
    "credential": "R.PH.",
    "title": "MANAGER",
    "telephone": "3334445555"
  },
  "names": [
    {
      "name_prefix": "DR",
      "first_name": "MARY",
      "middle_name": "JANE",
      "last_name": "SMITH",
      "name_suffix": "I",
      "credential": "CEO",
      "gender": "F",
      "primary": "Y"
    },
    {
      "organization_name": "DR ENTERPRISES",
      "organization_name_type": "3",
      "primary": "N"
    }
  ],
  "addresses": [
    {
      "type": "practice_address",
      "street1": "123 HERE ST",
      "street2": "STE 0",
      "city": "GRAND ISLAND",
      "state": "NE",
      "postal_code": "33345",
      "country_code": "US",
      "telephone": "5553334444",
      "fax": "5553334445"
    },
    {
      "type": "mailing_address",
      "street1": "234 THERE AVE",
      "city": "NEW YORK",
      "state": "NY",
      "postal_code": "44455",
      "country_code": "US",
      "telephone": "3335551111",
      "fax": "3335551112"
    }
  ],
  "taxonomies": [
    {
      "code": "282N00000X",
      "license": "94765",
      "primary": "Y",
      "state" "OH"
    }
  ],
  "identifiers": [
    {
      "code": "121634105",
      "type": "05",
      "state": "NY"
    }
  ]
}`}</Code>
      </Section>
    </Box>
  )
}

Documentation.propTypes = { store: PropTypes.object.isRequired }

export default withStore(Documentation)
