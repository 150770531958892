import React from 'react'
import ModalTransition from './ModalTransition'
import {
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardHeader,
  ModalCardTitle,
  ModalCardBody,
  ModalCardFooter,
  Button,
  Icon,
  Delete,
  Media,
  MediaLeft,
  MediaContent
} from 'bloomer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'

const Confirm = props => (
  <ModalTransition in={props.isActive}>
    <Modal isActive={props.isActive}>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <ModalCardTitle>{props.title}</ModalCardTitle>
          <Delete onClick={props.onCancel} />
        </ModalCardHeader>
        <ModalCardBody>
          <Media>
            <MediaLeft>
              <Icon hasTextColor={props.type} isSize="large">
                <FontAwesomeIcon icon={faExclamationTriangle} size="3x" />
              </Icon>
            </MediaLeft>
            <MediaContent>{props.children}</MediaContent>
          </Media>
        </ModalCardBody>
        <ModalCardFooter>
          <Button aria-label="cancel" onClick={props.onCancel}>
            Cancel
          </Button>
          <Button
            aria-label="login"
            isColor={props.type}
            onClick={props.onConfirm}
          >
            Confirm
          </Button>
        </ModalCardFooter>
      </ModalCard>
    </Modal>
  </ModalTransition>
)

Confirm.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}

Confirm.defaultProps = {
  isActive: false,
  title: 'Please Confirm',
  type: 'primary'
}

export default Confirm
