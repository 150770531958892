import React from 'react'
import Search from './Search'
import Landing from './Landing'
import { Box } from 'bloomer'
import { withStore } from './Store'
import PropTypes from 'prop-types'

const Home = ({ store }) => {
  document.title = `NPIAPI - ${store.user ? 'Testing' : 'Home'}`
  return <Box>{store.user ? <Search /> : <Landing />}</Box>
}

Home.propTypes = { store: PropTypes.object.isRequired }

export default withStore(Home)
