import { faSync } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Icon, Title } from 'bloomer'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import Clipboard from 'react-clipboard.js'
import api from './api'
import Confirm from './Confirm'
import Prompt from './Prompt'
import { withStore } from './Store'
import Wrap from './Wrap'

/* eslint jsx-a11y/click-events-have-key-events: 0 */
/* eslint jsx-a11y/no-noninteractive-element-interactions: 0 */
class AccountInfo extends Component {
  static propTypes = { store: PropTypes.object.isRequired }

  state = {
    confirmToken: false,
    promptEmail: false,
    promptName: false
  }

  changeName = () => {
    this.setState({ promptName: true })
  }

  changeEmail = () => {
    this.setState({ promptEmail: true })
  }

  saveEmail = value => {
    const { store } = this.props
    store.showSpinner()
    api
      .updateEmail(value)
      .then(() => {
        store.updateAccount()
        store.addAlert('success', 'Email Changed')
        this.setState({ promptEmail: false })
      })
      .catch(error => store.addAlert('danger', error))
      .finally(() => store.hideSpinner())
  }

  saveName = value => {
    const { store } = this.props
    store.showSpinner()
    api
      .updateProfile(value)
      .then(() => {
        store.updateAccount()
        store.addAlert('success', 'Name Changed')
        this.setState({ promptName: false })
      })
      .catch(error => store.addAlert('danger', error))
      .finally(() => store.hideSpinner())
  }

  changeToken = () => {
    const { store } = this.props
    store.showSpinner()
    api
      .changeToken()
      .then(() => {
        this.setState({ confirmToken: false })
        store.addAlert('success', 'Your Token was Reset')
      })
      .catch(error => store.addAlert('danger', error))
      .finally(() => store.hideSpinner())
  }

  onCopyToken = () => {
    const { store } = this.props
    store.addAlert('primary', 'Token Copied to Clipboard')
  }

  render() {
    const { account, user } = this.props.store
    const { confirmToken, promptEmail, promptName } = this.state
    return (
      <Fragment>
        <Box>
          <Title tag="h2">Account</Title>
          <Wrap>
            <table className="table is-fullwidth app-list hover">
              <tbody>
                <tr>
                  <th>Name</th>
                  <td onClick={this.changeName}>{account.displayName}</td>
                  <td />
                </tr>
                <tr>
                  <th>Email</th>
                  <td onClick={this.changeEmail}>{account.email}</td>
                  <td />
                </tr>
                <tr>
                  <th>Token</th>
                  <Clipboard
                    component="td"
                    onSuccess={this.onCopyToken}
                    data-clipboard-text={user.token}
                  >
                    {user.token}
                  </Clipboard>
                  <td className="has-text-right">
                    <Button
                      isColor="white"
                      onClick={() => this.setState({ confirmToken: true })}
                      isSize="small"
                    >
                      <Icon>
                        <FontAwesomeIcon icon={faSync} size="lg" />
                      </Icon>
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </Wrap>
        </Box>
        <Confirm
          isActive={confirmToken}
          title="Change Token"
          type="warning"
          onCancel={() => this.setState({ confirmToken: false })}
          onConfirm={this.changeToken}
        >
          <p>
            Your token is used to query the API. Are you sure you want to
            <strong> reset</strong> your token?
          </p>
        </Confirm>
        <Prompt
          isActive={promptEmail}
          title="Change Your Email"
          value={account.email || ''}
          type="primary"
          onCancel={() => this.setState({ promptEmail: false })}
          onSubmit={this.saveEmail}
        />
        <Prompt
          isActive={promptName}
          title="Change Your Name"
          value={account.displayName || ''}
          type="primary"
          onCancel={() => this.setState({ promptName: false })}
          onSubmit={this.saveName}
        />
      </Fragment>
    )
  }
}

export default withStore(AccountInfo)
